@import "../../../../global/homepage/scss/colors.scss";

.not-display {
  display: none;
}

.register-wrapper {
  background-color: $mainLight;
  position: relative;
  overflow: hidden;
  .register-section {
    min-height: 850px;
    max-width: 100vw;
    background: linear-gradient(90deg, rgba(252, 222, 103, 100%) 0%, rgba(91, 204, 246, 100%) 100%);
    background-size: cover;
    padding-top: 40px;
     .register-section-title {
       font-size: 16px;
       text-align: center;
       color: white;
     }
    .form-wrapper {
      width: 350px;
      margin: 30px auto;
      background: $backgroundWhite;
      border-radius: 10px;
      overflow: hidden;
      .tabs-wrapper {
        width: 101%;
      }
      .all-auth-form {
          input {
              width: 100%;
          }
      }
      .form-accept {
        margin: 20px 0 40px;
        .additional-info {
          p {
           padding: 5px 5px 20px;
           margin: 0;
           font-size: 12px;
           font-weight: 300;
          }
        }
        .collapse.in {
          display: block;
        }

        ul {
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
      .register-form-footer-wrapper {
        padding: 0 15px 15px 15px;
        .register-form-footer {
          border-top: 1px solid $shadowColor;
          padding: 30px 30px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          p {
            margin: 0 0 20px 0;
            font-size: 12px;
          }
        }
      }
    }
  }
  .register-car-img {
    position: absolute;
    height: 50px;
    left: -25px;
    bottom: 50px;
    display: none;
  }
  .register-person-img {
    position: absolute;
    right: 0;
    height: 150px;
    bottom: 20px;
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .register-wrapper {
    .register-section {
      min-height: 800px;
      margin-bottom: 150px;
      .form-wrapper {
        width: 700px;
        z-index: 1;
        position: relative;
        .register-form-footer-wrapper {
          padding: 0 30px 30px 30px;
          .register-form-footer  {
            flex-direction: row;
            p {
              margin: 0;
              font-size: 16px;
            }
          }
        }
      }
    }
    .register-car-img {
      left: -100px;
      height: 200px;
      bottom: 50px;
      display: block;
    }
    .register-person-img {
      right: 0;
      height: 200px;
      bottom: 5vh;
      display: none;
    }
  }
}


@media only screen and (min-width: 1200px) {
  .register-wrapper {
    .register-car-img {
      left: -350px;
      height: 300px;
      bottom: 150px;
    }
    .register-person-img {
      right: -20px;
      height: 400px;
      bottom: 5vh;
      display: block;
    }
  }
}
