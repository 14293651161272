@import "../../../../global/homepage/scss/colors.scss";

.opinion-card-data-wrapper {
  margin: 20px 0;
  &:last-of-type {
    .opinion-card-wrapper {
      border-bottom: none;
    }
  }
}
.opinion-card-wrapper {
  .big-screen-btn {
    display: none;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  border-bottom: 2px solid white;
  .opinion-card-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .header-content {
      .header-title {
        font-weight: bold;
        font-size: 16px;
        margin: 0;
        a {
          color: inherit;
        }
      }
      .header-title-data {
        p {
          display: flex;
          font-size: 12px;
          font-weight: lighter;
          margin: 5px 0;
          a {
            margin-left: 5px;
            color: $brandBlue;
          }
          .icon-certificate {
            margin: 0 0 5px 10px;
            height: 20px;
          }
        }
      }
    }
    .header-stars {
      .star-generate-wrapper {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        align-items: flex-start;
        .star-generate {
          .rate-star-wrapper {
            .rate-star {
              margin: 0 2px !important;
            }
          }
        }
        .star-rating {
          color: $brandBlue;
          font-size: 14px;
          font-weight: lighter;
          margin-left: 3px;
        }
      }
    }
  }
  .opinion-card-content {
    .card-photo {
      img {
        overflow: hidden;
        width: 100%;
        border-radius: 10px;
        margin: 15px 0;
        object-fit: cover;
      }
    }
    .card-content {
      .card-content-title {
        word-wrap: break-word;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        word-wrap: break-word;
      }
    }
  }
  .opinion-card-footer {
    .opinion-card-show-answer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      .see-answer {
        cursor: pointer;
        font-size: 15px;
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-size: 15px;
          color: $brandBlue;
        }
        .arrow-blue {
          margin-left: 10px;
        }
      }
      .opinion-btn {
        .opinion-button {
          &:focus {
            outline: 0;
          }
          font-size: 12px;
          padding: 6px 15px;
          cursor: pointer;
          color: $brandBlue;
          background: $backgroundWhite;
          border-radius: 5px;
          filter: grayscale(100%);
          transition: all 0.3s;
          line-height: 1;
          box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
          .add-text {
            display: none;
          }
          img {
            height: 15px;
            margin-left: 10px;
            margin-bottom: 4px;
          }
          &:hover {
            filter: grayscale(0);
          }
          &.liked {
            filter: grayscale(0);
          }
        }
        .dropdown-menu {
          margin-right: 15px;
          padding: 15px;
          p {
            font-size: 14px;
            line-height: 1.2;
          }
        }
      }
    }
    &.show-content {
      .opinion-card-answer {
        display: block;
      }
    }
    .opinion-card-answer {
      position: relative;
      margin-top: 20px;
      display: none;
      .card-answer-info {
        flex: 1 1 auto;
      }
      .card-answer-header {
        display: flex;
        .card-header-title {
          font-size: 16px;
          margin: 0 0 10px 0;
          .icon-certificate {
            height: 25px;
            margin-left: 10px;
          }
        }
        .card-header-content {
          p {
            font-size: 12px;
            margin: 2px 0;
            font-weight: lighter;
          }
        }
        .card-photo {
          flex: 0 0 auto;
          margin-right: 15px;
          width: 130px;
          border-radius: 15px;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .card-answer-content {
        margin: 15px 0 30px;
        .card-content {
          font-size: 14px;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
    background: white;
    border-bottom: none;
    .big-screen-btn {
      display: block;
    }
    .small-screen-btn {
      display: none;
    }
    .opinion-card-header {
      margin-bottom: 0;
      .header-content {
        position: relative;
        margin-bottom: 50px;
        .header-title {
          font-size: 26px;
          .google-opinion-icon {
            margin-left: 15px;
            margin-bottom: 4px;
          }
        }
        .header-title-data {
          display: flex;
          position: absolute;
          width: fit-content;
          max-width: 800px;
          p:nth-of-type(2) {
            padding: 0 20px;
            border-left: 1px solid $shadowColor;
            border-right: 1px solid $shadowColor;
          }
          p:first-of-type {
            padding-right: 20px;
          }
          p:last-of-type {
            padding-left: 20px;
          }
          p {
            font-size: 14px;
            white-space: nowrap;
            a {
              font-weight: bold;
              white-space: nowrap;
            }
          }
        }
      }
      .header-stars {
        .star-generate-wrapper {
          flex-direction: row;

          .star-generate {
            margin-left: 10px;
          }
          .star-rating {
            color: $brandBlue;
            font-size: 14px;
            font-weight: lighter;
            margin-left: 3px;
          }
        }
      }
    }
    .opinion-card-content {
      display: flex;
      .card-photo {
        margin-right: 30px;
        img {
          border-radius: 5px;
          margin-top: 0;
          width: 200px;
          object-fit: cover;
        }
      }
      .card-content {
        width: calc(100% - 250px);
        .card-content-title {
          margin-bottom: 15px;
          font-size: 16px;
          max-width: 100%;
          word-wrap: break-word;
        }
        p {
          font-size: 14px;
          word-wrap: break-word;
        }
      }
    }
    .opinion-card-footer {
      .opinion-card-show-answer {
        .see-answer {
          font-size: 1px;
          display: flex;
          align-items: center;
          p {
            margin: 0;
            font-size: 15px;
            color: $brandBlue;
          }
          .arrow-blue {
            margin-left: 10px;
          }
        }
        .opinion-btn {
          .opinion-button {
            &:focus {
              outline: 0;
            }
            display: flex;
            align-items: center;
            font-size: 16px;
            color: $brandBlue;
            background: transparent !important;
            box-shadow: none;
            .add-text {
              display: block;
            }
            .big-screen-btn {
              background: $brandBlue;
              transition: background-color 0.3s;
              height: 50px;
              width: 50px;
              margin-left: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              img {
                height: 28px;
                margin: 0;
              }
            }
          }
        }
      }
      .opinion-card-answer {
        position: relative;
        margin-top: 20px;

        display: none;
        .card-answer-header {
          .card-header-title {
            font-size: 22px;
            .icon-certificate {
              height: 25px;
              margin-left: 10px;
            }
          }
          .card-header-content {
            display: flex;
            margin-bottom: 20px;
            p {
              font-size: 14px;
            }
            p:last-of-type {
              padding: 0 20px;
              border-left: 1px solid $shadowColor;
            }
            p:first-of-type {
              padding-right: 20px;
            }
          }
          .card-photo {
            width: 200px;
            margin-right: 30px;
          }
        }
        .card-answer-content {
          .card-content {
            font-size: 14px;
            margin-top: 0;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    background: $backgroundWhite;
    max-width: 1200px;
    .opinion-card-header {
      .header-content {
        .header-title-data {
          p {
            font-size: 16px;
          }
        }
      }
    }
    .opinion-card-content {
      display: flex;
      .card-photo {
        margin-right: 30px;
        img {
          border-radius: 5px;
          margin-top: 0;
          width: 200px;
          object-fit: cover;
        }
      }
      .card-content {
        width: calc(100% - 250px);
        .card-content-title {
          font-size: 16px;
          max-width: 100%;
        }
        p {
          word-wrap: break-word;
          font-size: 14px;
        }
      }
    }
  }
}
