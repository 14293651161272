@import "../../../../global/homepage/scss/colors.scss";

.homepage-wrapper {
  background-color: $mainLight;
  overflow: hidden;
  .homepage-search-section {
    height: auto;
    max-width: 100vw;
    background-color: $brandOrange;
    background-size: cover;
    .container {
      .homepage-counters-section {
        box-sizing: border-box;
        padding-top: 20px;
        .stat-info-wrapper {
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          color: $mainDark;
          font-size: 12px;
          text-align: center;
          .stat-info-elem {
            display: flex;
            .stat-info-elem-long-text {
              display: none;
              margin-left: 3px;
            }
            .stat-info-elem-short-text {
              display: block;
              margin-left: 3px;
            }
            .stat-info-ico {
              display: inline-block;
              height: 25px;
              width: 25px;
              margin: 0 10px 3px 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .homepage-text-content {
      padding-top: 20px;
      font-family: Prometo;
      h1 {
        font-size: 18px;
        color: $mainDark;
        font-weight: bold;
        max-width: 350px;
        text-align: center;
        margin: 0 auto 25px auto;
        .first-line {
          font-size: 110%;
          margin: 0 auto 10px auto;
        }
        .second-line {
          margin: 0 auto 10px auto;
        }
      }
    }
    .homepage-form {
      margin: 0 auto;
      z-index: 1;
      position: relative;
      height: 488px;
    }
  }

  .homepage-person-img {
    position: absolute;
    right: 10%;
    height: 150px;
    bottom: -65px;
    z-index: 10;
    display: none;
  }
}

.homepage-video-section {
  background-color: white;
  position: relative;
  overflow: hidden;
  border-top: 5px solid $mainLight;
  .container {
    text-align: center;
    .homepage-video-tile {
      padding-top: 30px;
      padding-bottom: 10px;
      font-size: 18px;
      font-family: Prometo;
    }
    .homepage-video-content {
      padding-bottom: 10px;
      iframe {
        height: 200px;
      }
    }
  }
}

.homepage-links-section {
  background-color: $brandBlue;
  margin: 1px auto 0;
  text-align: center;
  p {
    font-size: 20px;
    color: $mainDark;
  }
  a {
    color: $mainDark;
  }

  .links-brands {
    vertical-align: top;
    padding: 30px 10px 10px 10px;
    font-family: Prometo;
    @media (min-width: 768px) {
      & {
        display: inline-block;
      }
    }
    ul {
      border-right: 1px white solid;
      -moz-column-count: 6;
      -webkit-column-count: 6;
      column-count: 6;
    }
    @media only screen and (max-width: 1037px) {
      ul {
        border-right: none;
      }
    }
    @media only screen and (max-width: 573px) {
      ul {
        -moz-column-count: 4;
        -webkit-column-count: 4;
        column-count: 4;
      }
    }
  }
  .links-voivodeships {
    display: inline-block;
    vertical-align: top;
    padding: 30px 10px 10px 10px;
    font-family: Prometo;
    ul {
        -moz-column-count: 4;
        -webkit-column-count: 4;
        column-count: 4;
    }
    @media only screen and (max-width: 573px) {
      padding: 10px;
      ul {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
      }
    }
  }
  .links-leasing-brands {
    vertical-align: top;
    padding: 30px 10px 10px 10px;
    font-family: Prometo;
    @media (min-width: 768px) {
      & {
        display: inline-block;
      }
    }
    ul {
      -moz-column-count: 8;
      -webkit-column-count: 8;
      column-count: 8;
    }
    @media only screen and (max-width: 1037px) {
      ul {
        -moz-column-count: 6;
        -webkit-column-count: 6;
        column-count: 6;
      }
    }
    @media only screen and (max-width: 573px) {
      ul {
        -moz-column-count: 4;
        -webkit-column-count: 4;
        column-count: 4;
      }
    }
  }

  li {
    font-size: 14px;
    line-height: 1;
  }
  ul {
    padding-inline-start: 0px;
    list-style-type: none;
  }
}

@media only screen and (min-width: 768px) {
  .homepage-wrapper {
    padding-bottom: 30px;
    .homepage-search-section {
      height: auto;

      .container {
        .homepage-counters-section {
          .stat-info-wrapper {
            font-size: 16px;
            .stat-info-elem {
              .stat-info-elem-long-text {
                display: block;
                margin-left: 3px;
              }
              .stat-info-elem-short-text {
                display: none;
                margin-left: 3px;
              }
            }
          }
        }
      }

      .homepage-text-content {
        padding-top: 40px;
        h1 {
          font-size: 24px;
          max-width: 750px;
          .first-line {
            margin: 0 auto 20px auto;
          }
          .second-line {
            margin: 0 auto 20px auto;
          }
        }
      }
      .homepage-form {
        height: 488px;
      }
    }
    .homepage-person-img {
      right: 1%;
      height: 300px;
      bottom: 0;
      display: block;
    }
  }

  .homepage-video-section {
    .container {
      .homepage-video-tile {
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 22px;
      }
      .homepage-video-content {
        padding-bottom: 10px;
        iframe {
          height: 400px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .homepage-wrapper {
    .homepage-search-section {
      .container {
        .homepage-counters-section {
          .stat-info-wrapper {
            font-size: 20px;
            .stat-info-elem {
              .stat-info-elem-long-text {
                display: block;
                margin-left: 3px;
              }
              .stat-info-elem-short-text {
                display: none;
                margin-left: 3px;
              }
            }
          }
        }
      }

      .homepage-text-content {
        padding-top: 60px;
        h1 {
          max-width: 850px;
          .first-line {
            font-size: 31px;
            margin: 0 auto 20px auto;
          }
          .second-line {
            font-size: 25px;
            margin: 0 auto 20px auto;
          }
        }
      }
      .homepage-form {
        height: 488px;
      }
    }
    .homepage-person-img {
      right: 50px;
      height: 400px;
      bottom: 0;
      display: block;
    }
  }

  .homepage-video-section {
    .container {
      .homepage-video-tile {
        padding-top: 30px;
        padding-bottom: 20px;
        font-size: 26px;
      }
      .homepage-video-content {
        padding-bottom: 10px;
        iframe {
          height: 500px;
        }
      }
    }
  }
}
