@import "../../../../global/homepage/scss/colors.scss";

.search-result-search-form {
  background-color: $brandOrange;
  background-size: cover;
  background-position: bottom;
  height: 400px;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  .search-result-car-img {
    position: absolute;
    bottom: 50px;
    right: -500px;
    width: 600px;
    z-index: 5;
    pointer-events: none;
  }
}

#big-workers-cards-place {
  overflow-x: auto;
  max-height: 1140px;
  min-height: 300px;
  &::-webkit-scrollbar {
    width: 0;
  }
}

#big-workers-cards-place2 {
  flex: 1 1 auto;
  overflow-y: auto;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.desktop {
  display: none !important;
}

.tablet {
  display: none;
}

.no-data-info {
  text-align: center;
  font-size: 24px;
  margin: 30px;
  display: none;
}

.active-checkbox {
  p {
    color: $brandOrange !important;
  }
}
.mobile-footer-wrapper {
  position: fixed;
  bottom: 20px;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  .mobile-switcher {
    width: 250px;

    .switch-view-tabs {
      display: flex;
      width: fit-content;
      overflow: hidden;
      width: 100%;
      font-size: 14px;
      .switch-view-tab {
        background-color: $backgroundWhite;
        width: 50%;
        border-top: 1px solid;
        border-bottom: 1px solid;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .switch-view-tab-left {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-left: 1px solid;
      }
      .switch-view-tab-right {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-right: 1px solid;
      }
      .switch-view-active-tab {
        border: 0 !important;
        background-color: $brandOrange;
        color: $textLight;
      }
    }
  }
  .up-btn {
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba($fullBlack, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    background: $backgroundWhite;
    height: 30px;
    width: 30px;
    margin-left: 10px;
    .arrow-blue {
      padding: 4px;
      margin-top: 4px;
    }
  }
}

.slide-wrapper {
  scroll-behavior: smooth;
  width: auto;
  max-width: 100vw;
  margin: 30px -15px 0;
  overflow: hidden;
  .slide-cards {
    max-width: 100vw;
    display: flex;
    padding: 0 0 20px;
    > div:first-of-type > .dealer-card-big-wrapper {
      margin-left: 15px;
    }
    > div:last-of-type > .dealer-card-big-wrapper {
      margin-right: 15px;
    }
  }
}
.sliders-navigation {
  margin-bottom: 25px;
  flex-wrap: wrap;
}
.dealer-card-big-wrapper {
  margin: 30px 20px;
}
.pagination-wrapper {
  .pagination {
    width: fit-content;
    margin: 0 auto;
  }
}

.search-map-tab {
  width: 100% !important;
  margin-left: 0;
  max-width: unset !important;
}

.icon-list {
  background-color: $mainLight;
  height: 20px;
  width: 20px;
  -webkit-mask-image: url("@/icons/icon-list.svg");
  mask-image: url("@/icons/icon-list.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.icon-pin {
  background-color: $mainLight;
  height: 20px;
  width: 20px;
  -webkit-mask-image: url("@/icons/icon-pin.svg");
  mask-image: url("@/icons/icon-pin.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.search-result-wrapper {
  //   overflow: hidden; usuwa przycisk z przejściem do widoku mapy dla desktopa
  .search-tab-section {
    display: none;
    max-width: 1200px;
    margin: -50px auto 0 auto;
    position: relative;
    .tabs-wrapper {
      width: 280px;
    }
  }

  .search-result-header-list {
    max-width: 1200px;
    // Fix margins according to UX
    margin: 60px 0 40px 0;
    flex-direction: column;
    display: none;
    // Fix margins according to UX
    span.all-salons-number {
      margin: 0 15px 0 5px;
    }
    // Fix spaces in select2 span
    span.select2 {
      margin-left: 10px;
    }
    .filter-option {
      width: 300px;
      background: transparent;
    }
    h1 {
      font-size: inherit;
      margin-bottom: 0;
    }
  }

  .search-result-header-list-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 65px;
    background: $mainLight;
    .mobile-header-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0 15px;
      max-width: 610px;
      width: 100%;
    }
    .search-result-header-list-buttons {
      display: flex;
      .button-mobile-search {
        margin-right: 10px;
        margin-left: 0;
        font-weight: 500;
        &:last-of-type {
          margin-right: 0;
        }
        .btn-icon {
          margin-right: 10px;
          height: 15px;
          width: auto;
        }
      }
    }
  }

  .search-result-content {
    .search-column-title {
      text-align: left;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .search-result-list {
      display: flex;
      flex-direction: column;
      max-width: 610px;
      padding: 0 15px;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      .search-result-header {
        display: block;
        text-align: left;
        margin: 30px 0 10px;
      }
      .search-result-list-dealership {
        padding-top: 20px;
        border-top: 1px solid $shadowColor;
        padding-bottom: 40px;
        min-height: 300px;
        .dealership-card-big-wrapper {
          margin: 30px 0 0;
        }
      }
      .search-result-list-dealer {
        padding-top: 20px;
        border-top: 1px solid $shadowColor;
        position: relative;
      }

      .search-result-additional-dealers {
        padding: 20px 0;
        border-top: 1px solid $shadowColor;
        width: 100%;
        margin: 0 auto;
        .search-column-title {
          text-align: left;
          font-size: 16px;
          margin-bottom: 20px;
        }
        .search-result-additional-card {
          display: flex;
          flex-wrap: wrap;
          margin: 30px -5px 0;
          .dealer-card-medium-wrapper {
            flex: 1 1;
            margin: 0 5px 30px;
            min-width: 280px;
          }
        }
      }
    }
    .search-result-map {
      margin-bottom: 100px;
      .map-list-section {
        .search-result-map-cards {
          height: 1000px;
          overflow-y: scroll;
          overflow-x: visible;
          padding: 0 16px;
          margin-left: -16px;
          min-width: 640px;
          display: flex;
          flex-direction: column;
          align-items: center;

          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }

          .dealership-card-small-wrapper {
            margin: 20px 0;
          }
        }
        .search-result-map-map {
          width: 100%;
          height: 600px;
          margin: 0 0 30px 0;
          @media (max-width: 767px) {
            height: 420px;
          }
          .marker-box {
            font-family: "Montserrat", sans-serif;
            font-weight: 400;
            min-width: 200px;
            max-width: 320px;
            .star-generate-wrapper {
              align-items: center;
            }
            .dealer-name {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              margin-top: 10px;
              font-size: 18px;
              margin-bottom: 0;
              color: $mainDark;
              line-height: 1.2;
              text-decoration: none;
            }
            .dealer-marks {
              display: none;
            }
            .dealer-link {
              margin-top: 20px;
              .arrow-blue {
                padding: 4px;
              }
              span {
                margin-right: 10px;
                color: $brandBlue;
                font-size: 14px;
                font-weight: 500;
              }
              a {
                text-decoration: none !important;
              }
            }
          }
        }
      }
      .all-dealers-section {
        width: 100%;
        margin: 0;
        padding: 0 5px;
        overflow: hidden;
        overflow-x: scroll;

        scrollbar-width: none;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }

        .dealer-card-medium-wrapper {
          display: flex;
          flex-direction: column;
          margin: 0 10px;
          // overflow: hidden;
        }
        .drag-content {
          display: flex;
          padding-bottom: 10px;
        }
        .verified-dealers {
          display: flex;
          flex-direction: column;
        }
        .not-verified-dealers {
          display: flex;
          flex-direction: column;
        }
        .search-column-title {
          margin-left: 10px;
        }
        .content {
          display: flex;
        }
      }
      .map-dealers-section {
        display: block;
        flex-direction: column;
        width: auto;
        padding: 0 15px;
        margin: 0 auto;
        overflow: hidden;
        .section-title {
          width: 100%;
          font-size: 16px;
          border-top: 1px solid $shadowColor;
          margin: 20px auto 0 auto;
          padding-top: 20px;
        }
        .map-dealers-cards-content {
          display: flex;
          flex-direction: column;
          .search-result-arrow {
            width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            .arrow-main {
              cursor: pointer;
              border-width: 0 3px 3px 0;
              padding: 12;
            }
          }
          .map-dealers-cards {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
          }
        }
      }
    }
  }

  .not-display-component {
    display: none !important;
  }

  .search-result-footer {
    display: flex;
    max-width: 610px;
    padding: 0 15px;
    margin: 10px auto 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .column-content {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .main-content {
        font-size: 20px;
        text-align: center;
      }
      .secondary-content {
        font-size: 16px;
        text-align: center;
        max-width: 300px;
      }
    }
    .column-image {
      width: 100%;
      margin-top: 25px;
      display: flex;
      justify-content: center;
      img {
        width: 400px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .mobile {
    display: none !important;
  }
  .tablet {
    display: block;
  }

  .pagination-wrapper {
    .pagination {
      margin: 30px auto;
    }
  }
  .search-result-wrapper {
    .search-result-header-list-mobile {
      .mobile-header-wrapper {
        justify-content: space-between;
        max-width: 808px;
        padding: 0 20px;
      }
    }
    .search-result-content {
      .search-result-list {
        padding: 0 20px;
        max-width: 808px;
        .search-result-list-wrapper {
          width: 100%;
        }
        .search-result-list-dealership {
          border-top: unset;
          .search-column-title {
            font-size: 20px;
            width: unset;
          }
        }
        .search-result-additional-dealers {
          margin: 0;
          max-width: unset;
          .search-result-additional-card {
            max-width: 750px;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
          }
        }
        .search-result-list-dealer {
          .search-column-title {
            font-size: 20px;
            width: unset;
          }
          .slide-wrapper {
            margin: 30px -10px 0;
            width: auto;
          }
        }
      }
      .search-result-map {
        .map-dealers-section {
          padding: 0 15px;
          width: 100%;
          .section-title {
            margin-bottom: 0;
          }
          .map-dealers-cards-content {
            width: 100%;
            .slide-wrapper {
              width: 100%;
            }
          }
        }
      }
    }
    .search-result-footer {
      max-width: 808px;
      padding: 0 20px;
      flex-direction: row;
      position: relative;
      margin: 50px auto 50px auto;
      align-items: flex-start;
      justify-content: flex-start;
      .column-content {
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 50%;
        .main-content {
          font-size: 40px;
          text-align: left;
        }
        .secondary-content {
          font-size: 16px;
          text-align: left;
        }
      }
      .column-image {
        width: 50%;
        img {
          width: 100%;
          height: auto;
          margin: 0 0 0 auto;
        }
      }
    }
  }

  .mobile-footer-wrapper {
    .mobile-switcher {
      width: 350px;
      .switch-view-tabs {
        font-size: 16px;
        .switch-view-tab {
          height: 45px;
        }
      }
    }
    .up-btn {
      height: 45px;
      width: 45px;
      .arrow-blue {
        padding: 6px;
        margin-top: 6px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .search-result-wrapper-map {
    max-width: 100vw !important;
    padding-right: 0 !important;
    .search-result-map-cards {
      width: 620px !important;
    }
    .search-result-map-map {
      width: 100% !important;
      height: 1000px !important;
    }
  }
  .tablet {
    display: none;
  }
  .desktop {
    display: block !important;
  }
  .small-screen {
    display: none !important;
  }
  .search-result-search-form {
    display: flex;
  }
  .search-result-wrapper {
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 30px;
    .search-tab-section {
      display: flex;
    }
    .search-result-header-list-mobile {
      display: none;
    }
    .search-result-header-list {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .search-result-content {
      .search-result-list {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        max-width: none;
        padding: 0;
        position: relative;
        .search-result-additional-dealers {
          border-top: unset;
        }
        .search-result-list-wrapper {
          flex: 1 1 auto;
          padding-right: 40px;
        }
        .search-result-list-dealership {
          .search-column-title {
            border-top: 1px solid $shadowColor;
            padding-top: 15px;
            font-size: 20px;
          }
        }
        .desktop {
          flex: 0 0 auto;
          padding-top: 20px;
          padding-bottom: 40px;
          width: 360px;
          .search-column-title {
            margin: 0 auto 20px;
            text-align: left;
            font-size: 20px;
            width: 320px;
            border-top: 1px solid $shadowColor;
            padding-top: 15px;
          }
          .dealer-card-big-wrapper {
            margin: 30px auto;
            &:first-of-type {
              margin-top: 10px;
            }
            &:last-of-type {
              margin-bottom: 10px;
            }
          }
          #dealers-arrow-up {
            margin-top: 40px;
          }
          .search-result-arrow {
            display: flex;
            justify-content: center;
            margin: 20px 0;

            .arrow-main {
              cursor: pointer;
              padding: 10px;
            }
          }
        }
      }
      .search-result-map {
        .map-dealers-section {
          padding: 0;
          .section-title {
            font-size: 20px;
            margin-bottom: 30px;
          }
          .map-dealers-cards-content {
            flex-direction: row;
            .desktop {
              display: flex !important;
            }
          }
        }
        .map-list-section {
          display: flex;
          justify-content: space-between;
          .search-result-map-map {
            width: 45%;
            height: 600px;
            // Fit map with cards
            flex: 1 1 55%;
            margin-left: 16px;
          }
          .search-result-map-cards {
            width: 50%;
            .search-column-title {
              font-size: 18px;
            }
          }
        }
      }
    }
    .search-result-footer {
      padding: 0;
      max-width: none;
      margin: 10px auto 50px auto;
      align-items: center;
      flex-direction: row;
      .column-content {
        width: 50%;
        align-items: flex-start;
        .main-content {
          font-size: 47px;
          text-align: left;
        }
        .secondary-content {
          font-size: 16px;
          max-width: 350px;
          text-align: left;
        }
      }
      .column-image {
        position: unset;
        width: 50%;
        padding-right: 30px;
        .search-result-people-img {
          width: 100%;
        }
      }
    }
  }
  .mobile-footer-wrapper {
    display: none;
  }
}

#render-medium-cards-map-not-verified-desktop {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  .dealer-card-medium-wrapper {
    // Fit cards with map
    flex-basis: 48%;
    margin-bottom: 4%;

    &:nth-child(odd) {
      margin-right: 4%;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .search-result-search-form {
    display: flex;
    .search-result-car-img {
      right: -300px;
    }
  }
}
