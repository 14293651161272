@mixin button-base {
  display: inline-block;
  border: none;
  border-radius: 10px;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 500;
  transition: box-shadow 0.3s;
  text-decoration: none !important;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0px 10px 20px $shadowColor;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      box-shadow: 0px 0px 0px transparent;
    }
  }

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    padding: 0 45px;
    border-radius: 15px;
    height: 50px;
    line-height: 50px;
  }
}
.open {
  .dropdown-menu {
    display: block !important;
  }
}

.button-primary,
.btn-primary {
  @include button-base;
  background: $mainDark;
  width: fit-content;
  color: $textLight;
  &:active {
    background-color: $mainDark !important;
    border-color: $mainDark !important;
  }
}

.button-secondary,
.btn-secondary {
  @include button-base;
  background: $brandBlue;
  color: $buttonTextColor;
  &:hover {
    color: $buttonTextColor;
  }
  &.disabled {
    pointer-events: none !important;
    background-color: $disabledColor !important;
    border-color: $disabledColor !important;
    box-shadow: none !important;
  }
}

.button-secondary-outline {
  @include button-base;
  border: 1px solid white;
  background: $brandBlue;
  color: $buttonTextColor;
  &:hover {
    color: $buttonTextColor;
  }
  &.disabled {
    pointer-events: none !important;
    background-color: $disabledColor !important;
    border-color: $disabledColor !important;
    box-shadow: none !important;
  }
}

.button-primary-outline {
  @include button-base;
  border: 1px solid white;
  background: $brandOrange;
  color: $buttonTextColor;
  &:hover {
    color: $buttonTextColor;
  }
  &.disabled {
    pointer-events: none !important;
    background-color: $disabledColor !important;
    border-color: $disabledColor !important;
    box-shadow: none !important;
  }
}

.button-primary-full {
  @include button-base;
  width: 100%;
  background: $mainDark;
  color: $textLight;
}

.button-secondary-full {
  @include button-base;
  width: 100%;
  background: $brandBlue;
  color: $buttonTextColor;
}

.button-primary-small {
  @include button-base;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $brandOrange;
  height: 35px !important;
  padding: 0 20px !important;
  font-size: 12px !important;
  color: $buttonTextColor;
}

.button-secondary-icon {
  padding: 15px 75px;
  background: $brandBlue;
  color: $buttonTextColor;
  font-size: 14px;
  border: none;
  border-radius: 15px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 10px 20px $shadowColor;
  }
  &:focus {
    outline: none;
  }

  img {
    height: 19px;
    width: auto;
    margin-right: 13px;
  }
}

.button-primary-small-icon {
  padding: 5px 20px;
  background: $brandOrange;
  color: $buttonTextColor;
  font-weight: 500;
  border: none;
  border-radius: 15px;
  font-size: 12px;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 10px 20px $shadowColor;
  }

  img {
    margin-right: 16px;
  }
}

.button-white-small {
  @include button-base;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $backgroundWhite;
  height: 35px !important;
  padding: 0 20px !important;
  font-size: 12px !important;
  color: $brandOrange;
}

button {
  background: none;
  border: none;
  padding: 0;

  &:focus {
    border: none;
    outline: none;
  }
}

.button-arrow {
  background: none;
  padding: 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
  color: $brandBlue;
  &:focus {
    outline: 0;
  }
  img {
    padding-left: 25px;
    transition: transform 0.3s;
  }

  &:hover {
    img {
      transform: translateX(10px);
    }
  }

  &.down {
    img {
      transform: rotate(90deg);
      padding-left: 0;
      margin-left: 23px;
    }

    &:hover {
      img {
        transform: rotate(90deg) translateX(5px);
      }
    }
  }
  &.up {
    img {
      transform: rotate(-90deg);
      padding-left: 0;
      margin-left: 23px;
    }

    &:hover {
      img {
        transform: rotate(-90deg) translateX(5px);
      }
    }
  }
}

.nav-mobile {
  display: inline-block;
  cursor: pointer;
  .bar1,
  .bar2,
  .bar3 {
    border-radius: 10px;
    width: 45px;
    height: 5px;
    background-color: $brandBlue;
    margin: 6px 0;
    transition: 0.4s;
  }
}

.change {
  .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-8px, 8px);
  }
  .bar2 {
    opacity: 0;
  }
  .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
}

.button-mobile-search {
  background: $backgroundWhite;
  border-radius: 10px;
  display: flex;
  font-size: 16px;
  color: $brandBlue;
  width: 130px;
  justify-content: center;
  align-items: center;
  height: 35px;
  margin: 5px;
  cursor: pointer;
  box-shadow: 0px 10px 20px $shadowButton;
}

.pagination {
  padding: 0 20px !important;
  margin-bottom: 20px !important;
  &.pagination-middle {
    justify-content: center;
  }
  .pagination-element {
    margin: 3px !important;
    height: 30px;
    width: 30px;
    min-width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $brandBlue;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    color: $brandBlue;
    transition: box-shadow 0.3s;

    &:hover,
    &:focus {
      outline: none;
      text-decoration: none;
      box-shadow: 0px 0px 10px rgba($fullBlack, 0.2);
    }

    &.active {
      background-color: $brandBlue;
      color: $buttonTextColor;
    }

    img {
      height: 14px;
      width: auto;
    }
  }

  #pagination-buttons {
    display: flex;
    flex-wrap: wrap;
  }
  .pagination-buttons-wrapper-element {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .disable-pagination {
    opacity: 0.1;
    pointer-events: none;
  }
}

.paginationjs {
  margin-top: 30px;
  .paginationjs-pages {
    padding-top: 50px;
    ul {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin: 3px !important;
        height: 30px;
        width: 30px;
        min-width: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $brandBlue;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 700;
        color: $brandBlue;
        transition: box-shadow 0.3s;

        a {
          color: inherit;
          text-decoration: none !important;
        }

        &:hover,
        &:focus {
          outline: none;
          text-decoration: none;
          box-shadow: 0px 0px 10px rgba($fullBlack, 0.2);
        }

        &.paginationjs-next,
        &.paginationjs-prev {
          &:before {
            content: url("@/global/arrow-pagination-2.svg");
            position: relative;
            top: -5px;
            display: block;
            height: 14px;
            width: 8px;
          }
          a {
            display: none;
          }
        }
        &.paginationjs-prev:before {
          transform: scaleX(-1);
        }

        &.active {
          background-color: $brandBlue;
          color: $buttonTextColor;
        }

        &.disabled {
          opacity: 0.1;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .paginationjs-pages {
      padding-top: 20px;
    }
  }
}

.sliders-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  .slider-nav {
    border: 2px solid $brandOrange;
    margin: 5px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    cursor: pointer;
  }
  .slider-nav-active {
    background: $brandOrange;
  }
}

.button-dropdown {
  box-shadow: 0px 0px 16px $shadowColor;
  height: max-content;
  width: max-content;
  padding: 0;
  border-radius: 15px;

  .button-secondary-icon {
    position: relative;
    background: $backgroundWhite;
    color: $brandBlue;
    margin: 0 !important;
    z-index: 2;
    padding: 15px 20px;
    transition: all 0.2s;
    display: flex;
    align-items: center;

    &:focus {
      border: 0;
      outline: none;
    }

    img {
      display: inline-block;
      height: 19px;
      width: auto;

      &.img-hover {
        display: none;
      }
    }

    &:hover {
      background: $brandBlue;
      span {
        color: $backgroundWhite !important;
      }
      img:not(.img-hover) {
        display: none;
      }

      img {
        &.img-hover {
          display: inline-block;
        }
      }
    }
  }

  &.show {
    .button-secondary-icon {
      &:hover {
        background: $backgroundWhite;
        color: $brandBlue;

        img {
          display: inline-block;

          &.img-hover {
            display: none;
          }
        }
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    border: 0;
    width: 100%;
    top: 40px !important;
    padding-top: 12px;
    box-shadow: 0px 0px 16px $shadowColor;
    z-index: 1;
    border-radius: 0px 0px 15px 15px;

    min-width: auto;

    .dropdown-item {
      text-align: center;
      font-size: 14px;
      color: $brandBlue;
      padding-top: 15px;
      padding-bottom: 15px;
      position: relative;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      transition: font-weight 0.3s;

      &:hover,
      &:focus {
        background-color: $backgroundWhite;
        font-weight: 800;
      }

      span.line {
        position: absolute;
        height: 1px;
        width: 80%;
        background-color: $brandBlue;
        left: 10%;
        top: -2px;
      }
    }
  }
}

.button-typo-icon-counter {
  background: none;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;

  p {
    display: inline-block;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: $brandBlue;
    transition: color 0.3s;
  }

  .icon-wrapper {
    height: 47px;
    width: 47px;
    background-color: $brandBlue;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    transition: background-color 0.3s;

    img {
      height: 22px;
      width: auto;
    }
  }

  &:hover {
    p {
      color: $brandOrange;
    }

    .icon-wrapper {
      background-color: $brandOrange;
    }
  }

  &.clicked {
    p {
      color: $brandOrange;
    }

    .icon-wrapper {
      background-color: $brandOrange;
    }
  }
}

.button-arrow-back {
  background: none;
  padding: 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
  color: $mainDark;
  &:focus {
    outline: 0;
  }
  @media only screen and (max-width: 615px) {
    font-size: 14px;
  }

  img {
    padding-right: 25px;

    &.hover {
      display: none;
    }
  }

  &:hover {
    color: $brandBlue;

    img.hover {
      display: inline-block;
    }

    img:not(.hover) {
      display: none;
    }
  }
}

.change-to-phone {
  span {
    color: $buttonTextColor;
  }
  .button-phone {
    display: none;
  }
}

.badge-icon,
.badge {
  margin-bottom: 7px;
  margin-right: 7px;
  padding: 5px 15px;
  border-radius: 10px !important;
// TODO add cypress tests before changes
  display: flex;
  flex-direction: row;
  cursor: pointer;
  width: max-content;
  align-items: center;
  border: 1px solid $mainDark;

  .icon-badge {
    width: 19px;
    height: auto;
    margin-right: 15px;

    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  img:not(.icon-badge) {
    height: 9px;
    width: 9px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    margin-right: 14px;

    @media only screen and (max-width: 576px) {
      font-size: 12px;
    }
  }

  &.active {
    background-color: $brandOrange;
    border-color: $brandOrange;

    span {
      color: $textLight;
    }
  }
}

.poznajdealera-badge {
  &-active{
    background-color: $brandOrange;
  };
    background-color: $brandBlue;
  border: none !important;
}

.price-badge {
  display: unset;
  border: none !important;
  background-color: $brandOrange;
  font-size: 60%;
}