.homepage {
  &--header {
    padding-top: 90px !important;
  }

  &--container {
    &--bouble {
      display: flex;

      &--circle {
        background: rgba(255, 255, 255, 50%) 0% 0% no-repeat padding-box;
        box-shadow: 0 3px 6px rgba(224, 224, 233, 80%);
        opacity: 1;
        backdrop-filter: blur(30px);
        width: 145px;
        height: 145px;
      }

      &--circle:hover {
        box-shadow: 0 0 30px 10px rgba(255, 255, 255, 80%);
      }
    }

    &--button {
      margin-top: 14px;
      display: flex;

      a {
        width: 100%;

        button {
          width: 100%;
          background: #f6a335 0 0 no-repeat padding-box;
          border-radius: 15px;
          text-align: center;
          font: normal normal medium 15px/15px Montserrat, serif;
          line-height: 20px;
          letter-spacing: 0;
          color: #ffff;
          opacity: 1;

          @media screen and (max-width: 991px) {
            height: 90px;
          }

          @media screen and (max-width: 767px) {
            height: 40px;
          }

          @media screen and (max-width: 389px) {
            height: 90px;
          }

          &:hover {
            box-shadow: 0 0 30px 10px #fff;
          }
        }
      }
    }

    &--news {
      margin-top: 22px;

      &--opinion {
        height: 300px;
        margin-bottom: 15px;

        @media screen and (max-width: 767px) {
          height: 230px;
        }

        @media screen and (max-width: 480px) {
          height: 210px;
        }

        background: #ffff 0 0 no-repeat padding-box;
        box-shadow: 0 10px 20px #0000000d;
        border-radius: 10px;
        opacity: 1;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 150px;

        &--name {
          text-align: left;
          font: normal normal 600 10px/16px Montserrat, serif;
          display: none;
          letter-spacing: 0;
          color: #404040;
          opacity: 1;
          margin-bottom: 2px;

          @media screen and (min-width: 768px) {
            font: normal normal 600 12px/16px Montserrat, serif;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        &--worker {
          text-align: left;
          font: normal normal normal 10px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          opacity: 1;
          margin-bottom: 2px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &--tile {
          text-align: center;
          font: italic normal 600 10px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          margin-bottom: 2px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          @media screen and (min-width: 768px) {
            font: italic normal 600 12px/16px Montserrat, serif;
          }
        }

        &--content {
          text-align: center;
          font: italic normal normal 10px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          margin-bottom: 5px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;

          @media screen and (min-width: 481px) {
            -webkit-line-clamp: 7;
          }

          @media screen and (min-width: 768px) {
            -webkit-line-clamp: 9;
          }

          @media screen and (min-width: 992px) {
            font: italic normal normal 12px/16px Montserrat, serif;
            -webkit-line-clamp: 10;
          }
        }

        &--type {
          text-align: left;
          font: normal normal bold 10px/24px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          opacity: 1;
          margin: auto;

          @media screen and (min-width: 768px) {
            font: normal normal bold 13px/34px Montserrat, serif;
          }
        }

        &--how {
          text-align: left;
          text-decoration: underline;
          font: normal normal normal 10px/12px Montserrat, serif;
          letter-spacing: 0;
          color: black;
          opacity: 1;
        }

        &--stars {
          width: 73px;

          &--icon {
            height: 13px;
          }
        }
      }

      &--offer {
        height: 300px;
        margin-bottom: 15px;

        @media screen and (max-width: 767px) {
          height: 230px;
        }

        @media screen and (max-width: 480px) {
          height: 210px;
        }

        background: #ffff 0 0 no-repeat padding-box;
        box-shadow: 0 10px 20px #0000000d;
        border-radius: 10px;
        opacity: 1;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 150px;

        &--type {
          text-align: left;
          white-space: nowrap;
          font: normal normal bold 10px/24px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          margin-bottom: 17px;
          opacity: 1;

          @media screen and (min-width: 768px) {
            font: normal normal bold 13px/34px Montserrat, serif;
          }
        }

        &--name {
          text-align: center;
          font: normal normal 600 12px/16px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          margin-top: 5px;
          word-wrap: none;
          text-overflow: ellipsis;

          @media screen and (min-width: 992px) {
            font: normal normal 600 16px/16px Montserrat, serif;
          }
        }

        &--image {
          background: transparent grey 0 0 no-repeat padding-box;
          box-shadow: 0 3px 6px #00000029;
          border-radius: 12px;
          opacity: 1;
          object-fit: cover;

          @media screen and (min-width: 1200px) {
            width: 415px;
            max-height: 188px;
          }

          @media screen and (max-width: 1199px) {
            width: 340px;
            max-height: 173px;
          }

          @media screen and (max-width: 991px) {
            width: 240px;
            max-height: 160px;
          }

          @media screen and (max-width: 767px) {
            width: 180px;
            max-height: 130px;
          }

          @media screen and (max-width: 480px) {
            width: 120px;
            max-height: 80px;
          }
        }

        &--price {
          text-align: center;
          font: normal normal normal 12px/20px Montserrat, serif;
          letter-spacing: 0;
          color: #404040;
          margin-top: 5px;
          margin-bottom: 5px;

          @media screen and (min-width: 992px) {
            font: normal normal 600 16px/16px Montserrat, serif;
          }

          @media screen and (max-width: 991px) {
            bottom: -35px;
          }
        }

        &--how {
          text-align: left;
          text-decoration: underline;
          font: normal normal normal 10px/8px Montserrat, serif;
          letter-spacing: 0;
          color: black;
          opacity: 1;

          @media screen and (max-width: 480px) {
            font: normal normal normal 7px/8px Montserrat, serif;
          }
        }
      }
    }
  }
}
