@import "../../../global/homepage/scss/colors.scss";
@import "../../../global/homepage/scss/_mixins.scss";
// Global
@import "./tabs";
@import "./tables";
@import "./cards-boxes";
@import "./modals";

// To components
@import "./core_view";
@import "./userAccountMessages";
@import "./userMessageDetails";
@import "./userOpinions";
@import "./options";

.user-account {
  padding-bottom: 30px;
  min-height: calc(100vh - 90px - 260px);
  @media only screen and (min-width: 768px) {
    padding-bottom: 50px;
    min-height: calc(100vh - 90px - 160px);
  }
  @media only screen and (min-width: 992) {
    padding-bottom: 100px;
  }
  @media only screen and (min-width: 1200) {
    min-height: calc(100vh - 90px - 180px);
  }

  .user-account-main {
    background-color: $mainLight;
    padding-bottom: 100px;
    @media only screen and (max-width: 991px) {
      padding-bottom: 50px 0;
    }
    @media only screen and (max-width: 767px) {
      padding-bottom: 30px 0;
    }
  }

  .container {
    @media only screen and (max-width: 769px) {
      max-width: 100%;
    }
    &-user-account{
      padding-right: 15px;
      padding-left: 15px;

      .form-accept {
        font-size: 18px;
        padding-bottom: 10px;

        ul {
          margin-bottom: 0;
          margin-top: 5px;
        }

        @media only screen and (max-width: 991px) {
          font-size: 16px;
        }
        @media only screen and (max-width: 680px) {
          font-size: 14px;
        }
      }
    }
  }

  section.content {
    .container {
      background-color: $backgroundWhite;
      border-radius: 0px 15px 15px 15px;
      padding: 50px;
      padding-bottom: 50px;
      padding-top: 50px;

      @media only screen and (max-width: 1200px) {
        padding: 30px 15px;
      }
    }
  }

  section.name {
    padding: 60px 0;

    @media only screen and (max-width: 991px) {
      padding: 40px 0;
    }
    @media only screen and (max-width: 680px) {
      padding: 10px 0 20px;
    }

    h1 {
      margin-bottom: 0;
      font-weight: 400;
      color: $mainDark;
      font-size: 42px;

      @media only screen and (max-width: 991px) {
        font-size: 30px;
      }
      @media only screen and (max-width: 680px) {
        font-size: 20px;
      }
    }
  }
}