.tabs-wrapper {
    display: flex;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
    height: 50px;
    .tab {
        padding: 0 15px;
        flex: 1 1 0;
        background-color: $mainDark;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $mainLight;
        font-size: 12px;
        cursor: pointer;
        .tab-icon {
            margin-right: 10px;
        }
        &:not(:first-child) {
            border-left: 1px solid $shadowColorTransparent;
        }
    }
    .active-tab {
        background-color: $backgroundWhite;
        color: $mainDark;
        border: 0;
        .tab-icon {
            background-color: $mainDark;
        }
    }
}

@media only screen and (min-width: 768px) {
    .tabs-wrapper {
        .tab {
            font-size: 16px;
        }
    }
}

.switch-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    .main-content {
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        .switch-content-tab {
            font-size: 12px;
            padding: 5px 7px;
            font-weight: normal;
            white-space: nowrap;
        }
        .active-tab {
            color: $brandBlue;
            border-bottom: 2px solid $brandOrange;
        }
    }
    .additional-content {
        margin-bottom: 10px;
    }

    @media only screen and (min-width: 768px) {
        flex-direction: row;
        .main-content {
            width: 100%;
            .switch-content-tab {
                font-size: 14px;
                padding: 5px 15px;
                font-weight: bold;
            }
        }
        .additional-content {
            margin-left: 0;
            margin-bottom: 20px;
            margin-top: 30px;
        }
    }
    @media only screen and (min-width: 1200px) {
        .main-content {
            width: auto !important;
            .switch-content-tab {
                font-size: 16px;
                padding: 10px 25px;
            }
        }
        .additional-content {
            margin-left: auto;
            margin-bottom: 0;
            margin-top: 0 !important;
        }
    }
}
