@import "../../../../global/homepage/scss/colors.scss";
@import "../../components/opinionCards/opinionCards.scss";
@import "../../components/dealerCardSmall/dealerCardSmall.scss";
@import "./modalPhone.scss";

.glowing-bg {
    position: relative;
    margin: 10px;
    background-color: transparent;
}

.glowing-bg-div1::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 50%;
  -webkit-box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
          box-shadow: 0 0 17px 3px #ffff01,0 0 4px 2px #ffff01;
  z-index: inherit;
  -webkit-animation-name: yellow-shadow;
          animation-name: yellow-shadow;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.glowing-bg-div1::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  -webkit-box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
          box-shadow: 0 0 17px 3px #0ff,0 0 4px 2px #0ff;
  z-index: inherit;
  -webkit-animation-name: cyan-shadow;
          animation-name: cyan-shadow;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
@-webkit-keyframes yellow-shadow {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 50%;
    left: 0;
  }
  50% {
    top: 50%;
    left: 50%;
  }
  75% {
    top: 0;
    left: 50%;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@keyframes yellow-shadow {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 50%;
    left: 0;
  }
  50% {
    top: 50%;
    left: 50%;
  }
  75% {
    top: 0;
    left: 50%;
  }
  100% {
    top: 0;
    left: 0;
  }
}

@-webkit-keyframes cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
  }
  25% {
    right: 0;
    bottom: 50%;
  }
  50% {
    right: 50%;
    bottom: 50%;
  }
  75% {
    right: 50%;
    bottom: 0;
  }
  100% {
    right: 0;
    bottom: 0;
  }
}

@keyframes cyan-shadow {
  0% {
    right: 0;
    bottom: 0;
  }
  25% {
    right: 0;
    bottom: 50%;
  }
  50% {
    right: 50%;
    bottom: 50%;
  }
  75% {
    right: 50%;
    bottom: 0;
  }
  100% {
    right: 0;
    bottom: 0;
  }
}

a.opinion-link{
  color:inherit
}
a.opinion-link:link{
  color: inherit;
}
a.opinion-link:visited{
  color:inherit;
}

.active-checkbox {
  p {
    color: $brandOrange !important;
  }
}

.single-dealer-wrapper {
  padding-top: 90px;
  position: relative;
  .dropdown-item {
    &:active {
      border: 0 !important;
      outline: 0 !important;
    }
  }
  .up-btn {
    position: fixed;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba($fullBlack, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    background: $backgroundWhite;
    height: 30px;
    width: 30px;
    margin-left: 10px;
    bottom: 30px;
    right: 30px;
    .arrow-blue {
      padding: 4px;
      margin-top: 4px;
    }
  }

  .tablet-desktop {
    display: none !important;
  }

  .big-screen {
    display: none;
  }

  .owl-dot {
    outline: 0;
  }

  .not-display {
    display: none !important;
  }

  .owl-dot.active span {
    background: $brandOrange !important;
  }

  .owl-dot span {
    border: 2px solid $brandOrange !important;
    background: white !important;
    width: 12px !important;
    height: 12px !important;
  }

  .single-dealer-main {
    .main-container {
      margin: 0 auto;
      padding: 0 15px;
      width: 100%;
      max-width: 414px;
    }
    .result-information {
      font-size: 12px;
      margin: 0 auto 15px auto;
      img {
        margin: 0 5px 3px 0;
      }
      a {
        color: $mainDark;
      }
    }
    .dealer-leasing-offers-links {
      text-align: center;
      margin: 30px 0 15px 0;
      padding: 15px 15px 15px 15px;
      border-radius: 15px;
      border-color: $brandBlue;
      border-style: solid;
      border-width: 1px;
      background: white;

      @media only screen and (max-width: 768px) {
        margin: 20px 0 10px 0;

        h2 {
          font-size: 16px;
        }
        img {
          display: none;
        }
      }

      .button-secondary {
        padding: 0 30px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 2px;
      }
    }
    .information-tab {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: $mainLight;
      .information-tab-row {
        margin: 0 auto;
        display: flex;
        align-items: center;
        max-width: 414px;
        width: 100%;
        min-height: 90px;
        justify-content: space-between;

        .information-tab-contact-info {
          display: flex;

          .information-tab-phones {
            display: flex;
            justify-content: center;
            white-space: nowrap;
            margin: 5px;
          }
        }

        .contact-button-wrapper {
          display: flex;
          align-items: center;
          .item {
            color: #0062aa;
            height: 50%;
            margin: 10px;
            position: relative;
            white-space: nowrap;
          }

          .arrow1 {
            animation: slide1 1s ease-in-out infinite;
            margin-left: 9px;
          }

          @keyframes slide1 {
            0%,
            100% {
                transform: translate(0, 0);
            }
            50% {
              transform: translate(10px, 0);
            }
          }

          .button-dropdown {
            width: 170px;
            .button-secondary-icon {
              white-space: nowrap;
            }
          }
        }
      }
    }
    .card-opinion-wrapper {
      .dealer-card {
        margin: 0 auto;
        width: 100%;
        .dealer-photo-wrapper-no-img {
          //             width: 100%;
          height: 150px;
        }
        .dealer-photo-wrapper {
          position: relative;
          margin: 0 -15px;
          .dealer-photo {
            width: 100%;
            height: 300px;
            object-fit: cover;
          }
          .photo-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(
              180deg,
              #ffffff00 10%,
              #ffffff0b 0%,
              #ffffffe6 80%,
              #ffffff 100%
            );
          }
        }
        .card-information-wrapper {
          margin: -150px 0 20px 0;
          position: relative;
          padding-bottom: 40px;
          z-index: 1;
          border-bottom: 2px solid $brandBlue;
          .card-title-container {
            .card-title {
              display: block;
              margin-bottom: 10px;
              font-size: 20px;
              word-break: break-word;
              max-width: 640px;
            }
          }
          .social-icons {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -140px;
            right: 0;
            .icon-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              background: $backgroundWhite;
              box-shadow: 0px 0px 16px $shadowColor;
              width: 31px;
              height: 31px;
              border-radius: 5px;
              margin: 5px;
              cursor: pointer;
            }
          }
          .card-data {
            display: flex;
            .button-primary-outline {
              padding: 0 15px;
            }
            .card-data-pin {
              width: 46px;
              height: 42px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: $brandOrange;
              border-radius: 5px;
              text-decoration: none !important;
            }
            .card-data-info {
              display: flex;
              flex-direction: column;
              p {
                margin: 0 0 3px 10px;
                font-size: 14px;
              }
            }
          }
          .car-brand-logo-wrapper {
            display: flex;
            margin-top: 5px;
            .car-brand-logo {
              height: 31px;
              width: fit-content;
              color: $mainDark;
              margin: 0 5px 5px 0;
              img {
                filter: brightness(0%);
                max-width: 60px;
              }
            }
          }
        }
      }
      .opinion-dealer {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 2px solid $brandBlue;
        .button-secondary {
          height: 50px;
          width: 45%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          white-space: nowrap;
          text-decoration: none;
          &:hover {
            color: white;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
        .opinion {
          .opinion-data {
            display: flex;
            width: 45%;
            align-items: center;
            margin-top: -10px;
            .percentage {
              font-size: 29px;
              margin-right: 5px;
              margin-bottom: 0;
              color: $brandBlue;
            }
            .opinion-text p {
              font-size: 10px;
              text-transform: uppercase;
              margin-bottom: 0;
              color: $brandBlue;
              line-height: 1.2;
            }
          }
        }
      }
    }
    .information-dealer {
      margin: 10px 0 0 0;
      padding: 0 0 10px 0;
      border-bottom: 2px solid $brandBlue;
      //   mobile only
      .website-info-row {
        width: 100% !important;
        display: flex;
        justify-content: center;
        a {
          .button-secondary-icon {
            background: #ffffff;
            color: #0062aa;
            transition: all 0.2s;
            padding: 10px 15px;
            border-radius: 15px;
            border-color: #0062aa;
            border-style: solid;
            border-width: 1px;
            &:hover {
              color: white;
              background: #0062aa;
              img {
                filter: brightness(0) invert(1);
              }
            }
            img {
              margin-right: 5px;
            }
          }
        }
      }
      .info-row {
        display: flex;
        justify-content: space-between;
        p {
          padding-right: 30px;
          font-size: 14px;
          margin: 5px 0;
        }
      }
      .button-mobile-search {
        width: 100%;
        margin: 20px auto;
        height: 45px;
        white-space: nowrap;
        .button-secondary-icon {
          width: 100% !important;
          display: flex;
          justify-content: center;
        }
        img {
          margin-right: 15px;
        }
      }
    }
    .open-hours-description {
      margin: 20px auto;
      .open-hours {
        width: 100%;
        .open-hours-background {
          display: none;
        }
        .open-hours-title {
          font-weight: bold;
          font-size: 16px;
        }
        .open-hours-data {
          margin: 5px 0;
          font-size: 14px;
          line-height: 20px;
          &:last-of-type {
            margin-bottom: 20px;
          }
          label {
            margin: 0;
            width: 100px;
          }
        }
      }
      .description {
        margin-top: 20px;
        .description-title {
          font-weight: bold;
          font-size: 16px;
        }
        .description-content {
          font-size: 14px;
          height: 100px;
          line-height: 20px;
          overflow: hidden;
          overflow-wrap: break-word;
        }
        .full-content {
          height: unset;
        }
      }
      .see-more-text {
        font-size: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 2px solid $brandBlue;
        p {
          margin: 0;
          color: $brandBlue;
        }
        .arrow-blue {
          margin-left: 10px;
        }
      }
    }
    .gallery-mobile {
      margin: 0 auto;
      border-bottom: 2px solid $brandBlue;
      padding-bottom: 20px;
      height: 277px;
      .owl-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        max-height: 216px;
      }
    }
    .gallery-big-screen {
      display: none;
    }
    @media only screen and (min-width: 768px) {
      .main-container {
        padding: 0 20px;
        max-width: 808px;
      }
      .tablet-desktop {
        display: flex !important;
      }
      .result-information {
        padding: 0;
        font-size: 14px;
        margin: 20px auto 30px auto;
      }
      .information-tab {
        .information-tab-row {
          padding: 0 20px;
          max-width: 808px;
        }
      }
      .card-opinion-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        .dealer-card {
          margin: 0;
          display: flex;
          .dealer-photo-wrapper {
            width: 200px;
            flex: 0 0 auto;
            margin: 0;
            .dealer-photo {
              width: 100%;
              min-height: 180px;
              height: unset !important;
              object-fit: cover;
            }
            .photo-overlay {
              display: none;
            }
          }
          .card-information-wrapper {
            flex: 1 1 auto;
            margin: 0;
            padding-bottom: 0;
            border-bottom: 0;
            padding-left: 10px;
            padding-right: 20px;
            .card-title-container {
              margin-bottom: 10px;
              .card-title {
                font-size: 18px;
                line-height: 1.2;
                margin-bottom: 20px;
              }
            }
            .social-icons {
              position: unset;
              justify-content: flex-start;
              margin: 25px 0 15px;
              .icon-wrapper {
                margin: 0 5px 0 0;
              }
            }
            .card-data {
              display: flex;
              .card-data-info {
                display: flex;
                p {
                  margin: 0;
                  font-size: 14px;
                }
              }
            }
            .car-brand-logo-wrapper {
              display: flex;
              flex-wrap: wrap;
              margin-top: 15px;
            }
          }
        }
        .opinion-dealer {
          flex-direction: column;
          justify-content: flex-start;
          width: fit-content;
          margin: 0;
          border-bottom: 0;
          padding: 0;
          .button-secondary {
            width: fit-content;
            font-size: 16px;
            margin-top: 10px;
            img {
              width: 22px;
              height: 22px;
            }
          }
          .opinion {
            flex: 0 0 auto;
            .opinion-data {
              display: flex;
              width: fit-content;
              align-items: center;
              margin-top: -10px;
              .percentage {
                font-size: 35px;
                margin-right: 5px;
                margin-bottom: 0;
                color: $brandBlue;
              }
              .opinion-text p {
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 0;
                color: $brandBlue;
              }
            }
            .rate-star {
              height: 20px;
              width: 20px;
              margin: 0 4px;
            }
          }
        }
      }

      .open-hours-description {
        margin: 40px auto;
        display: flex;
        align-items: flex-start;
        .open-hours {
          position: relative;
          width: 40%;
          padding: 25px 25px 25px 0;
          background: $mainLight;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          min-height: 200px;
          .open-hours-background {
            background: $mainLight;
            width: 100vw;
            height: 100%;
            display: block;
            position: absolute;
            z-index: -1;
            right: 20px;
            top: 0;
          }
          .open-hours-title {
            font-weight: bold;
            font-size: 18px;
          }
          .open-hours-data {
            margin: 7px 0;
            font-size: 16px;
            line-height: 20px;
            &:last-of-type {
              margin-bottom: 20px;
            }
            label {
              margin: 0;
              width: 100px;
            }
          }
        }
        .description {
          width: 50%;
          margin-left: 10%;
          margin-top: 0;
          .description-title {
            font-weight: bold;
            font-size: 22px;
          }
          .description-content {
            font-size: 18px;
            height: 100px;
            overflow: hidden;
          }
          .full-content {
            height: unset;
          }
        }
        .see-more-text {
          font-size: 15px;
          display: flex;
          cursor: pointer;
          align-items: center;
          padding-bottom: 20px;
          border-bottom: 0;
          p {
            margin: 0;
            color: $brandBlue;
          }
          .arrow-blue {
            margin-left: 10px;
          }
        }
      }
      .gallery-mobile {
        display: none;
      }
      .gallery-big-screen {
        display: flex;
        margin: 0 auto;
        .lb-prev {
          opacity: 1 !important;
          display: block !important;
        }
        .lb-next {
          opacity: 1 !important;
          display: block !important;
        }
        .main-photo {
          position: relative;
          width: 80%;
          height: 300px;
          border-radius: 10px;
          overflow: hidden;
          .img-prev,
          .img-next {
            position: absolute;
            top: 0;
            width: 10%;
            height: 100%;
            text-align: center;
            img {
              height: 45px;
              width: auto;
            }
          }
          .img-prev {
            left: 0;
            mix-blend-mode: difference;
          }
          .img-next {
            right: 0;
            mix-blend-mode: difference;
            img {
              transform: scaleX(-1);
            }
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .drag-content-wrapper {
          padding-left: 10px;
          width: 20%;
          height: 300px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          a {
            cursor: pointer;
            display: block;
            width: 100%;
            height: 57px;
            margin-bottom: 3px;
            &:last-of-type {
              margin-bottom: 0;
            }
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              overflow: hidden;
              object-fit: cover;
            }
          }
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      .main-container {
        max-width: 1240px;
      }
      .up-btn {
        display: none;
      }
      .result-information {
        font-size: 16px;
        margin: 20px auto 30px auto;
      }
      .information-tab {
        .information-tab-row {
          max-width: 1240px;
          .information-tab-content {
            font-size: 16px;
            padding-right: 30px;
          }
          .button-mobile-search {
            width: 200px;
          }
        }
      }
      .card-opinion-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        .dealer-card {
          width: 75%;
          max-width: none;
          .dealer-photo-wrapper {
            width: 200px;
            flex: 0 0 auto;
            .dealer-photo {
              width: 100%;
              object-fit: cover;
            }
            .photo-overlay {
              display: none;
            }
          }
          .card-information-wrapper {
            padding-left: 15px;
            padding-right: 30px;
            display: flex;
            flex-direction: column;
            .card-title-container {
              .card-title {
                font-size: 24px;
                margin-bottom: 20px;
              }
            }
            .card-data {
              .card-data-info {
                flex-direction: row;
              }
            }
            .car-brand-logo-wrapper {
              flex: 1 1 100%;
              display: flex;
            }
            .social-icons {
              margin: 20px 0 0;
            }
          }
        }
        .opinion-dealer {
          flex-direction: column;
          justify-content: flex-start;
          width: 25%;
          margin: 0;
          border-bottom: 0;
          .button-secondary {
            width: 100%;
            font-size: 16px;
            margin-top: 10px;
            img {
              width: 22px;
              height: 22px;
            }
          }
          .opinion {
            .opinion-data {
              display: flex;
              width: fit-content;
              align-items: center;
              margin-top: -10px;
              .percentage {
                font-size: 50px;
                font-weight: bold;
                margin-right: 5px;
                margin-bottom: 0;
                color: $brandBlue;
              }
              .opinion-text p {
                font-size: 14px;
                text-transform: uppercase;
                margin-bottom: 0;
                color: $brandBlue;
                &:first-of-type {
                  font-size: 16px;
                }
                &:last-of-type {
                  font-size: 18px;
                }
              }
            }
            .star-generate-wrapper {
              span {
                font-size: 23px;
              }
              .star-generate {
                font-size: 23px;
                .rate-star {
                  height: 28px;
                  width: 27px;
                  margin: 0 4px;
                }
              }
            }
          }
        }
      }

      .open-hours-description {
        margin: 40px auto;
        width: 100%;
        display: flex;
        .open-hours {
          position: relative;
          width: 25%;
          padding: 25px 10px 25px 0;
          background: $mainLight;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          .open-hours-background {
            background: $mainLight;
            width: 100vw;
            height: 100%;
            display: block;
            position: absolute;
            z-index: -1;
            right: 20px;
            top: 0;
          }
          .open-hours-title {
            font-weight: bold;
            font-size: 18px;
          }
          .open-hours-data {
            margin: 7px 0;
            font-size: 16px;
            line-height: 20px;
          }
        }
        .description {
          width: 60%;
          margin-left: 10%;
          margin-top: 0;
          .description-title {
            font-weight: bold;
            font-size: 22px;
          }
          .description-content {
            font-size: 16px;
            height: fit-content;
          }
        }
        .see-more-text {
          display: none;
        }
      }
      .gallery-big-screen {
        width: 100%;
        display: flex;
        margin: 0 auto;
        .main-photo {
          width: 80%;
          height: 500px;
          border-radius: 10px;
        }
        .drag-content-wrapper {
          width: 20%;
          height: 500px;
          .drag-content {
            overflow-y: auto;
            overflow-x: hidden;
          }
          .drag-content::-webkit-scrollbar {
            width: 0;
          }
          a {
            height: 92px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .tablet-desktop {
      display: flex;
    }
    .mobile {
      display: none !important;
    }
  }
}

.single-dealer-wrapper {
  .single-dealer-information-tabs {
    .tabs-nav {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      margin-top: -50px;
      transition: all 0.3s;
      z-index: 10;
      .page-info {
        opacity: 0;
        border-bottom: 1px solid $mainDark;
        transition: all 0.3s;
        .page-name {
          display: block;
          max-width: 100%;
          line-height: 50px;
          font-weight: bolder;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.sticky {
        background: $mainLight;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
        .page-info {
          opacity: 1;
        }
        .tabs-wrapper {
          border-radius: 0 !important;
          .tab {
            border-radius: 0 !important;
          }
        }
      }
    }
    .section-title {
      display: flex;
      font-size: 16px;
      width: 100%;
      border-top: 2px solid $shadowColor;
      margin: 20px auto;
      padding: 10px 0 8px;
      .see-more-cards {
        padding-bottom: 20px;
        padding-right: 5px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        border-bottom: 0;
        cursor: pointer;
        p {
          margin: 0;
          color: $brandBlue;
        }
        .arrow-blue {
          margin-left: 10px;
        }
      }
    }
    margin-top: 50px;
    .tabs-wrapper {
      .tab {
        text-align: center;
        font-size: 10px;
        img {
          display: none;
        }
        span {
          color: $mainLight;
        }
      }

      .active-tab {
        background: $mainLight;
        font-weight: bold;
        img {
          display: none;
        }
        span {
          color: $mainDark;
        }
      }
    }

    .single-dealer-information-content {
      background: $mainLight;
      padding: 20px 0;
      .single-dealer-cards {
        .owl-cards .owl-stage-outer {
          overflow: hidden;
        }
        .static-cards {
          display: none;
        }
        width: 100%;
        margin: 0 auto 20px auto;
        .owl-stage {
          padding-top: 10px !important;
          padding-bottom: 10px !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
          .dealer-card-big-wrapper {
            margin: 0 auto;
            @media only screen and (min-width: 420px) {
              &:first-of-type {
                margin-right: 10px;
                margin-left: 0;
              }
              &:last-of-type {
                margin-right: 0px;
                margin-left: 10;
              }
            }
          }
        }
      }

      .single-dealer-convenience {
        .icon-convenience {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 10px;
          row-gap: 10px;
          overflow: hidden;
          .icon {
            width: auto;
          }
        }
        .see-more-wrapper {
          width: 100%;
          max-width: 410px;

          margin: 15px auto;
          .see-more-text-icons {
            font-size: 15px;
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            p {
              margin: 0;
              color: $brandBlue;
            }
            .arrow-blue {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .single-dealer-opinions-section {
      padding: 0 0 10px;
      .opinion-card-data-wrapper {
        margin: 20px 0;
      }
      .section-title {
        display: flex;
        .see-more-all {
          cursor: pointer;
          margin-left: 20px;
          display: flex;
          align-items: center;
          padding-bottom: 20px;
          padding-right: 5px;
          p {
            margin: 0;
            color: $brandBlue;
          }
          .arrow-blue {
            margin-left: 10px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .single-dealer-information-tabs {
      .section-title {
        display: flex;
        font-size: 18px;
        .see-more-cards {
          margin-left: 20px;
          font-size: 18px;
          display: flex;
          align-items: center;
          border-bottom: 0;
          cursor: pointer;
          p {
            margin: 0;
            color: $brandBlue;
          }
          .arrow-blue {
            margin-left: 10px;
          }
        }
      }
      margin-top: 50px;
      .tabs-wrapper {
        margin: 0 auto;
        .tab {
          font-size: 16px;
          img {
            display: block;
            margin-right: 10px;
            height: 26px;
            &.active {
              display: none;
            }
          }
          &.active-tab {
            img {
              display: none;
              &.active {
                display: block;
              }
            }
          }
        }
        .active-tab {
          background: $mainLight;
          font-weight: bold;
        }
      }

      .single-dealer-information-content {
        background: $mainLight;
        padding: 20px 0;
        .single-dealer-cards {
          width: 100%;
          margin: 0 auto 20px auto;
        }

        .single-dealer-convenience {
          .icon-convenience {
            grid-template-columns: repeat(4, 1fr);
          }
          .see-more-wrapper {
            width: 100%;
            max-width: 410px;
            margin: 15px auto;
            .see-more-text-icons {
              display: none;
            }
          }
        }
      }
      .single-dealer-opinions-section {
        padding: 0 0 100px;
        .section-title {
          display: flex;
          .see-more-all {
            display: flex;
            margin-left: 20px;
            align-items: center;
            padding-bottom: 20px;
            p {
              margin: 0;
              color: $brandBlue;
            }
            .arrow-blue {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .single-dealer-information-tabs {
      .section-title {
        font-size: 18px;
        width: 100%;
        display: flex;
      }
      margin-top: 50px;
      .tabs-wrapper {
        .tab {
          max-width: 250px;
          &:last-of-type {
            border-top-right-radius: 10px;
            overflow: hidden;
          }
        }
      }

      .single-dealer-information-content {
        .single-dealer-convenience {
          .icon-convenience {
            grid-template-columns: repeat(6, 1fr);
            column-gap: 15px;
            row-gap: 15px;
          }
        }
        .single-dealer-cards {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto 20px auto;
          .owl-cards {
            display: none;
          }
          .static-cards {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 50px;
            margin-left: -10px;
            margin-right: -10px;
            .worker-card-wrapper {
              padding: 0 10px;
              width: 33.33%;
            }
            .dealer-card-big-wrapper {
              width: 100%;
              height: 100%;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
.tab-workers-wrapper {
  width: 100%;
  background: $mainLight;
  padding: 1px 0 0;
  .tab-workers-content {
    margin-top: 25px;
    margin: 0 auto;
    .switch-content {
      width: 100%;
      margin: 10px 0;
    }
    .workers-cards {
      display: flex;
      margin-left: -10px;
      margin-right: -10px;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-bottom: 50px;
      .worker-card-wrapper {
        padding: 0 10px;
        margin-bottom: 20px;
        width: 100%;
        .dealer-card-big-wrapper {
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }
    }
    @media only screen and (min-width: 768px) {
      .switch-content {
        margin: 20px 0;
      }
      .workers-cards {
        .worker-card-wrapper {
          width: 50%;
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      .switch-content {
        margin: 30px 0;
      }
      .workers-cards {
        .worker-card-wrapper {
          width: 33.33%;
        }
      }
    }
  }
}
.opinions-cards-wrapper {
  background: $mainLight;
  padding-top: 1px;
  padding: 1px 0 0;
  .opinions-cards {
    margin: -1px auto 0;
    .opinion-card-data-wrapper {
      margin: 20px 0;
    }
    .switch-content {
      width: 100%;
      margin: 10px 0;
      .additional-content {
        display: flex;
        align-items: center;
        select {
          font-size: 12px;
        }
        p {
          margin: 0 10px 0 0;
          font-size: 12px;
        }
      }
    }
    .workers-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .worker-card-content {
        margin: 5px;
      }
    }
    @media only screen and (min-width: 768px) {
      .switch-content {
        margin: 20px 0;
        .additional-content {
          select {
            font-size: 14px;
          }
          p {
            font-size: 14px;
          }
        }
      }
      .workers-cards {
        .worker-card-content {
          margin: 15px 5px;
        }
      }
    }
    @media only screen and (min-width: 1200px) {
      .switch-content {
        margin: 30px 0;
        .additional-content {
          select {
            font-size: 16px;
          }
          p {
            font-size: 16px;
          }
        }
      }

      .workers-cards {
        .worker-card-content {
          margin: 25px 5px;
        }
      }
    }
    .pagination-wrapper {
      padding: 20px 0 20px 0;
    }
    .google_opinions_last_update {
      font-size: 12px;
      color: grey;
      text-align: right;
      padding: 0 0 20px 0;
    }
  }
}

// Unverified section

.unverified-information-header {
  width: 100%;
  margin: 0 auto;
  border-bottom: 4px solid $brandBlue;
  p {
    max-width: 360px;
    color: $brandBlue;
    margin: 45px auto 5px auto;
    text-align: center;
  }
}

.unverified-information-body {
  max-width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto 20px auto;
  .information-section {
    .title {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }
    .content {
      font-size: 14px;
      text-align: center;
    }
    .button-wrapper {
      margin: 0 auto;
      width: fit-content;
      .button-primary {
        text-decoration: none;
        &:hover {
          color: $textLight;
        }
      }
    }
  }
  .photo-section {
    margin-top: 40px;
    width: 100%;
    img {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .unverified-information-body {
    max-width: 700px;
    flex-direction: row;
    .information-section {
      width: 50%;
      margin-top: 40px;
      .title {
        font-size: 24px;
        font-weight: 700;
        text-align: left;
      }
      .content {
        font-size: 16px;
        width: 80%;
        text-align: left;
      }
      .button-wrapper {
        margin: 0;
        width: fit-content;
        .button-primary {
          text-decoration: none;
          &:hover {
            color: $textLight;
          }
        }
      }
    }
    .photo-section {
      margin-top: 40px;
      width: 50%;
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .unverified-information-header {
    border-bottom: 5px solid $brandBlue;
    p {
      max-width: 100%;
      font-size: 20px;
      color: $brandBlue;
      margin: 65px auto 10px auto;
    }
  }

  .unverified-information-body {
    padding: 0 20px;
    max-width: 1200px;
    .information-section {
      width: 35%;
      margin-top: 120px;
      .title {
        font-size: 32px;
      }
      .content {
        font-size: 16px;
      }
      .button-wrapper {
        margin-top: 20px;
      }
    }
    .photo-section {
      width: 65%;
    }
  }
}

#inactive-worker-message-wrapper {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: flex;
  justify-content: center;
  min-height: 85px;
  width: 95%;
  align-items: center;
  background-color: $brandOrange;
  box-shadow: 0 0 16px $shadowButton;
  border-radius: 15px;

  p {
    font-size: 20px;
    color: $mainLight;
    margin: 0;
    padding: 0 15px;
    text-align: center;
  }
}

